import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { logout } from "../../services/logout";
import { LuSettings } from "react-icons/lu";
import { isAdministrator } from "../../core/utils/functions";

const NavBar = (props) => {
  const { group } = props;
  const { pathname } = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    window.$(".btn-expanded").on("click", function () {
      console.log("dkfohjj");
      if (window.$(this).hasClass("btn-collapsed")) {
        window.$(this).removeClass("btn-collapsed");
        window.$("div.nav").removeClass("close-nav");
      } else {
        window.$(this).addClass("btn-collapsed");
        window.$("div.nav").addClass("close-nav");
      }
    });
  }, []);

  return (
    <div className="nav">
      <Link className="btn btn-expanded" to="#"></Link>
      <nav className="nav-main-menu">
        <ul className="main-menu">
          <li>
            {" "}
            <Link
              className={`dashboard2 ${pathname === "/" ? "active" : ""}`}
              to="/"
            >
              <img
                src="/assets/imgs/page/dashboard/dashboard.svg"
                alt="Cible rh emploie"
              />
              <span className="name">{t("textDashboard")}</span>
            </Link>
          </li>
            <>
              <li>
                {" "}
                <Link
                  className={`dashboard2 ${
                    pathname.includes("/users") ? "active" : ""
                  }`}
                  to="/users"
                >
                  <img
                    src="/assets/imgs/page/dashboard/candidates.svg"
                    alt="Cible rh emploie"
                  />
                  <span className="name">{t("textUsers")}</span>
                </Link>
              </li>
            </>
          
          <li>
            {" "}
            <Link
              className={`dashboard2 ${
                pathname.includes("/news") ? "active" : ""
              }`}
              to="/news"
            >
              <img
                src="/assets/imgs/page/dashboard/tasks.svg"
                alt="Cible rh emploie"
              />
              <span className="name">{t("NewsLetter")}</span>
            </Link>
          </li>
          <li>
            {" "}
            <Link
              className={`dashboard2 ${
                pathname.includes("/profile") ? "active" : ""
              }`}
              to="/profile"
            >
              <img
                src="/assets/imgs/page/dashboard/profiles.svg"
                alt="Cible rh emploie"
              />
              <span className="name">{t("textMyProfile")}</span>
            </Link>
          </li>

          <li>
            {" "}
            <Link className={`dashboard2 `} to="#" onClick={() => logout(t)}>
              <img
                src="/assets/imgs/page/dashboard/logout.svg"
                alt="Cible rh emploie"
              />
              <span className="name">{t("textLogout")}</span>
            </Link>
          </li>
        </ul>
      </nav>
      {/* <div className="border-bottom mb-20 mt-20"></div> */}
    </div>
  );
};

export default NavBar;
