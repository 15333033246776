import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./store/slices/auth";
import localeReducer from "./store/slices/lannguage";

const store = configureStore({
  reducer: {
    auth: authReducer,
    locale: localeReducer,
  },
});

export { store };
