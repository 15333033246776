import { URLS } from "../../services/urls";


let ls = {}

ls.set = window.localStorage.setItem.bind(localStorage);
ls.get = window.localStorage.getItem.bind(localStorage);

const getUserDetails = () => JSON.parse( window.localStorage.getItem("userDetails"));
const setUserDetails = (details) => window.localStorage.setItem("userDetails", JSON.stringify(details));

const appScrollTo = (id) => {
    const element = document.getElementById(id);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
}

const isAdministrator = (userDetails = null) =>
  ["manager"].includes(
    userDetails ? userDetails?.user.user_level : getUserDetails()?.user.user_level
  );

const formatLinkString = (link) => {
  if(link?.includes("https://")){
    return link;
  } else {
    return URLS.BASE_URL + link;
  }
}


export {ls, setUserDetails, getUserDetails, appScrollTo, isAdministrator, formatLinkString}

{/*const isAdministrator = (user = null) =>
  ["manager"].includes(
    user ? user?.user.user_level : getUserDetails()?.user.user_level
  );*/}