import { Outlet } from "react-router-dom";
import AuthHeader from "../header/AuthHeader";
import NavBar from "../navBar";
import PrivateRoute from "../../route/components/PrivateRoute";
import AuthFooter from "../footer";

const UserDashboardLayout = () => {
  return (
    <PrivateRoute>
      <AuthHeader />
      <main className="main dashboard">
        <NavBar />
        <Outlet />
      </main>
      <AuthFooter />
    </PrivateRoute>
  );
};

export default UserDashboardLayout;
