import React from "react";
import { Card, CardBody, Row } from "reactstrap";
import NewsLetter from "../newsletter/Newsletter";

const ActualityNews = () => {
  return (
    <div className="box-content">
      <div className="page-content">
        <div className="container-fluid">
          <Card>
            <CardBody>
              <Row>
                <div>
                  <NewsLetter />
                </div>
              </Row>
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default ActualityNews;
