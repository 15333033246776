export const URLS = {
  BASE_URL: "https://api.ciblerh-emploi.com/",
  CLIENT_ID: "rgFz0scCkvH2qAm4iBWwk5m8f6qC9SVM5RHzrKze",
  AUTHS: {
    LOGIN: "auth/login/",
    REFRESH: "auth/token/refresh/",
    LOGOUT: "auth/logout/",
    REGISTRATION: "user/accounts/registration/",
    MANAGER_REGISTRATION: "accounts/staff/registration/",
    VERIFY_EMAIL: (key) => `user/accounts/confirm-email/${key}/`,
    VERIFY_ACCOUNT: (id) => `/users/verify/${id}/`,
    resetPassword: "accounts/password-reset/",
    resetPasswordAdmin: "accounts/password/admin-reset/",
    me: "users/me/",
    PASSWORD: {
      reset: "auth/password/reset/",
      confirm: "auth/password/reset/confirm/",
    },
    VERIFY: {
      email: "user/accounts/email/verification/",
    },
  },
  DASHBOARD: {
    admin: "/dashboard/admin/",
    adminInterval: (start, end) =>
      `/dashboard/admin/interval/?start=${start}&end=${end}`,
  },
  USERS: {
    update: (id) => `users/${id}/`,
    list: (search, page, extra, limit) =>
      `users/?search=${search}&offset=${((page ?? 1) - 1) * 25}&limit=${
        limit ?? 25
      }&page=${page}${extra ?? ""}`,

    ADMIN: {
      create: "users/admin/",
      list: (search, page, extra, limit) =>
        `users/admin/?search=${search}&offset=${((page ?? 1) - 1) * 25}&limit=${
          limit ?? 25
        }&page=${page}${extra ?? ""}`,
      update: (id) => `users/admin/${id}/`,
      delete: (id) => `users/admin/${id}/`,
    },
  },

  NEWSLETTER: {
    SUBSCRIPTION: {
      create: "newsletter/subscriptions/",
      list: "newsletter/subscriptions/",
      listnews: (search) => `newsletter/news/?search=${search}`,
      createnews: "newsletter/news/",
      updatenews: (id) => `newsletter/news/${id}/`,
    },
  },

  CAROUSSEL: {
    create: "caroussel-content/",
    list: (search) => `caroussel-content/?search=${search}`,
    update: (id) => `caroussel-content/${id}/`,
    delete: (id) => `caroussel-content/${id}/`,
    details: (id) => `caroussel-content/${id}/`,

    CAROUSSEL_OTHER: {
      create: "/caroussel-content/images/",
      details: (id) => `caroussel-content/images/${id}/`,
    },
  },

  LOGGING: {
    list: (search, page, extra) =>
      `/logging/?search=${search}&offset=${((page ?? 1) - 1) * 25}&page=${
        page ?? 1
      }${extra ?? ""}`,
  },
  JOBS: {
    list: (search, page, extra) =>
      `/jobs/admin/?search=${search}&offset=${((page ?? 1) - 1) * 25}&page=${
        page ?? 1
      }${extra ?? ""}`,
  },
};
