import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "../../services/logout";
import useSWR from "swr";
import { URLS } from "../../services/urls";
import NotifDetails from "./components/NotifDetails";
import { useState } from "react";
import { API } from "../../services/axios";
import { toast } from "react-toastify";
import dash from "../../assets/img/dashboard/dash.png";
import { formatLinkString } from "../../core/utils/functions";
import LangSwitcher from "./components/LangSwitcher";
import { DEFAULT_PROFILE } from "../../core/utils/consts";


const AuthHeader = () => {
  const { t } = useTranslation();

  const [notif, setNotif] = useState();

  const handleDisplayNotif = (id) => {
    API.get(URLS.NOTIFICATIONS.details(id))
      .then((resp) => {
        const { data } = resp;
        setNotif(data);
        window.$("#NotificationDetails").modal("show");
      })
      .catch((error) => {
        toast.error(
          error?.response?.data?.detail ??
            error?.response?.data?.message ??
            t("textNotifDetailsError")
        );
      });
  };

  const { auth } = useSelector((state) => state.auth);

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  
  const handleLinkClick = (path) => {
    window.location.href = path;
    toggleMenu(); // ferme le menu
  };

  return (
    <>
<header className="header sticky-bar">
        <div className="container">
          <div className="main-header">
            <div className="header-left">
              <div className="header-logo">
                <Link className="d-flex" to="/">
                  <img alt="Cible rh emploie" src={dash} />
                </Link>
              </div>
            </div>
            {/* <div className="header-search">
              <div className="box-search">Admin area
                <form action="">
                  <input
                    className="form-control input-search"
                    type="text"
                    name="keyword"
                    placeholder="Search"
                  />
                </form>
              </div>
            </div> */}
            <div className="header-menu d-none d-md-block">
              <ul>
                <ul></ul>
              </ul>
            </div>
            <div className="header-right">
              <div className="block-signin block-signin-with-lang">
                <LangSwitcher />
                <div className="dropdown d-inline-block">
                  <Link
                    className="btn btn-notify"
                    id="dropdownNotify"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    data-bs-display="static"
                  ></Link>
                  <ul
                    className="dropdown-menu dropdown-menu-light dropdown-menu-end"
                    aria-labelledby="dropdownNotify"
                  >
             
                  
                    {/* <li>
                      <Link className="dropdown-item" to="#" onClick={() => handleDisplayNotif(2)}>
                        <small>
                        <strong>Title</strong>
                        </small>
                        <br />
                        <small>Content</small>
                      </Link>
                    </li> */}
                  </ul>
                </div>
                <div className="member-login">
                  <img
                    alt=""
                    src={
                      auth?.user?.picture
                        ? formatLinkString(auth?.user?.picture)
                        : DEFAULT_PROFILE
                    }
                  />
                  <div className="info-member">
                    {" "}
                    <strong className="color-brand-1">
                      {auth?.user?.first_name} {auth?.user?.last_name}
                    </strong>
              
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div
        className={`burger-icon burger-icon-white ${
          menuOpen ? "burger-close" : ""
        }`}
        onClick={toggleMenu}
      >
        <span className="burger-icon-top"></span>
        <span className="burger-icon-mid"></span>
        <span className="burger-icon-bottom"></span>
      </div>
      {menuOpen && (
     
            <div className="perfect-scroll">
              <div className="mobile-search mobile-header-border mb-30">
                <form action="#">
                  <input type="text" placeholder="Search…" />
                  <i className="fi-rr-search"></i>
                </form>
              </div>
              <div className="mobile-menu-wrap mobile-header-border">
                {/* <!-- mobile menu start--> */}
                <nav>
                  <ul className="main-menu">
                    <li>
                      {" "}
                      <Link className="dashboard2 active " to="/">
                        <img
                          src="/assets/imgs/page/dashboard/dashboard.svg"
                          alt="Cible rh emploie"
                        />
                        <span className="name">{t("textDashboard")}</span>
                      </Link>
                    </li>
                    <li>
                      {" "}
                      <Link
                      onClick={() => handleLinkClick("/profile")}
                        className="dashboard2 text-left"
                        to="/profile"
                      >
                        <img
                          src="/assets/imgs/page/dashboard/profiles.svg"
                          alt="Cible rh emploie"
                        />
                        <span className="name">{t("textMyProfile")}</span>
                      </Link>
                    </li>
                   
                    <li>
                      {" "}
                      <Link
                        className="dashboard2 text-left"
                        to="#"
                        onClick={() => logout(t)}
                      >
                        <img
                          src="/assets/imgs/page/dashboard/logout.svg"
                          alt="Cible rh emploie"
                        />
                        <span className="name">{t("textLogout")}</span>
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            
            </div>
         
      )}

    </>
  );
};
export default AuthHeader;
