import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { HiOutlineStar } from "react-icons/hi2";
import { HiUsers } from "react-icons/hi2";
import { HiMiniUserCircle } from "react-icons/hi2";
import { MdOutlineEmail } from "react-icons/md";
import { GiSkills, GiDiploma } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
//import { isAdministrator } from "../../core/utils/functions";
import { useEffect } from "react";

const Users = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  //const isAdmin = isAdministrator();

 {/*useEffect(() => {
    if (!isAdmin) {
      navigate("/login");
    }
  }, [isAdmin, navigate]);*/}

  return (
    <div className="box-content">
      <div className="box-heading">
        <div className="box-title">
          <h3 className="mb-35 fw-bold">{t("Users")}</h3>
        </div>
        <div className="box-breadcrumb">
          <div className="breadcrumbs">
            <ul>
              <li>
                {" "}
                <Link className="icon-home" to="/">
                  {t("textDashboard")}
                </Link>
              </li>
              <li>
                <span>{t("txtUsers")}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="section-box">
            <div className="container">
              <div className="row">
                <div className="col-md-3 col-sm-4 col-12">
                  <div className=" configuration-item">
                    <Link to="/users/admin">
                      <div className="item-logo d-flex">
                        <div className="image-left">
                          <HiMiniUserCircle fontSize={45} />
                        </div>
                        <div className="text-info-right d-flex">
                          <h4 className="m-auto">{t("textAdmin")}</h4>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-md-3 col-sm-4 col-12">
                  <div className=" configuration-item">
                    <Link to="/news">
                      <div className="item-logo d-flex">
                        <div className="image-left">
                          <HiUsers fontSize={45} />
                        </div>
                        <div className="text-info-right d-flex">
                          <h4 className="m-auto">{t("News")}</h4>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Users;
