import { useState } from "react";
import { useTranslation } from "react-i18next";
import { API } from "../../services/axios";
import { URLS } from "../../services/urls";
import { toast } from "react-toastify";
import News from "./News";

const NewsLetter = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);

    const emails = data
      .get("email")
      .split(",")
      .map((email) => email.trim());

    setLoading(true);

    // Use Promise.all to handle multiple async requests
    Promise.all(
      emails.map((email) =>
        API.post(URLS.NEWSLETTER.SUBSCRIPTION.create, { email })
      )
    )
      .then((responses) => {
        setLoading(false);
        toast.success(t("textThankForSubscribing"));
      })
      .catch((error) => {
        console.log(error?.response?.data);
        setLoading(false);
        toast.error(
          error?.response?.data?.email[0] ?? t("textNewsletterFailed")
        );
      });
  };

  return (
    <section className="section-box mt-50 mb-20">
      <div className="container">
        <div className="box-newsletter">
          <div className="row">
            <div className="col-xl-3 col-12 text-center d-none d-xl-block">
              <img
                src="/assets/imgs/template/newsletter-left.png"
                alt="joxBox"
              />
            </div>
            <div className="col-lg-12 col-xl-6 col-12">
              <h2
                className="text-md-newsletter text-center"
                dangerouslySetInnerHTML={{ __html: t("News Will") }}
              ></h2>
              <div className="box-form-newsletter mt-40">
                <form className="form-newsletter" onSubmit={handleSubmit}>
                  <input
                    className="input-newsletter"
                    required
                    type="text" // Change type to "text"
                    name="email"
                    placeholder={t("test1@gmail.com, test2@gmail.com")}
                  />
                  <button
                    className="btn btn-default font-heading icon-send-letter d-flex"
                    type="submit"
                    disabled={loading}
                  >
                    <span className="my-auto">{t("Subscribe")} </span>
                    {loading && (
                      <div
                        className="spinner-border my-auto ml-10 spinner-border-sm"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}{" "}
                  </button>
                </form>
              </div>
            </div>
            <div className="col-xl-3 col-12 text-center d-none d-xl-block">
              <img
                src="/assets/imgs/template/newsletter-right.png"
                alt="joxBox"
              />
            </div>
          </div>
        </div>
      </div>
      <News />
    </section>
  );
};

export default NewsLetter;
