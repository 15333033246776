import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { API } from "../../services/axios";
import { URLS } from "../../services/urls";

const NewsManage = ({ toEdit, onSuccess }) => {
  const { register, reset, handleSubmit } = useForm();
  const { t } = useTranslation();
  const { auth } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");

  const onSubmit = (data) => {
    let toSend = data;
    toSend.content = message;
    toSend.title = title;
    toSend.written_by = auth.user.id;
    setLoading(true);
    if (toEdit) {
      API.put(URLS.NEWSLETTER.SUBSCRIPTION.update(toEdit.id), toSend)
        .then((resp) => {
          toast.success(t("textConfigEditionSuccess"));
          setLoading(false);
          onSuccess();
          reset();
          window.$("#manageIndustryForm").modal("hide");
        })
        .catch((error) => {
          console.log(error);
          toast.error(
            error?.response?.data?.detail ??
              error?.response?.data?.message ??
              t("textConfigEditionError")
          );
          setLoading(false);
        });
    } else {
      API.post(URLS.NEWSLETTER.SUBSCRIPTION.create, toSend)
        .then((resp) => {
          toast.success(t("textConfigCreatedSuccess"));
          setLoading(false);
          onSuccess();
          reset();
          window.$("#manageIndustryForm").modal("hide");
        })
        .catch((error) => {
          console.log(error);
          toast.error(
            error?.response?.data?.detail ??
              error?.response?.data?.message ??
              t("textConfigCreatedError")
          );
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (toEdit) {
      setMessage(toEdit?.content);
    }
  }, [toEdit]);

  return (
    <div
      className="modal fade"
      id="manageIndustryForm"
      tabIndex={-1}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content apply-job-form">
          <button
            className="btn-close"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
          <div className="modal-body pl-30 pr-30 pt-50">
            <div className="text-center">
              <p className="font-sm text-brand-2">
                {toEdit ? t("textEdit") : t("textSendNews")}
              </p>
              {/* <h2 className="mt-10 mb-5 text-brand-1 text-capitalize">Start your career today</h2> */}
              {/* <p className="font-sm text-muted mb-30">Please fill in your information and send it to the employer.                   </p> */}
            </div>
            <form
              className="login-register text-start mt-20 pb-30"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="form-group">
                <label className="form-label" htmlFor="input-13">
                  {t("textSubject")} <span className="text-primary">*</span>
                </label>
                <input
                  defaultValue={toEdit?.title}
                  className="form-control"
                  type="text"
                  {...register("title", { required: true })}
                />
              </div>

              <div className="form-group">
                <label className="form-label" htmlFor="des">
                  {t("textMessage")} <span className="text-primary">*</span>
                </label>
                <textarea
                  defaultValue={toEdit?.message}
                  className="form-control"
                  type="textarea"
                  {...register("message", { required: true })}
                />
              </div>
              <div className="row"></div>
              <div className="form-group">
                <button
                  className="btn btn-default hover-up w-100"
                  type="submit"
                  name="login"
                >
                  {toEdit ? t("textEdit") : t("textSendNews")}
                  {loading && (
                    <div
                      className="spinner-border ml-10 spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
              {/* <div className="text-muted text-center">Do you need support? <a href="page-contact.html">Contact Us</a></div> */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsManage;
