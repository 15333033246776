import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NotFound from "../pages/notfound/NotFound";
import Login from "../pages/login";
import RecoverPassword from "../pages/recoverPassword/RecoverPassword";
import ConfirmRecover from "../pages/recoverPassword/Confirm";
import UserDashboardLayout from "../component/Layouts/UserDashboard";
import UserDashboard from "../pages/dashboard/Index";
import Logging from "../pages/log";
import Users from "../pages/users/Index";
import AdminList from "../pages/users/admin/Index";
import Caroussel from "../pages/caroussel/Index";
import MyProfile from "../pages/myProfile";
import ActualityNews from "../pages/news/Index";

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="login" element={<Login />} />
        <Route path="password-reset" element={<RecoverPassword />} />
        <Route
          path="password-reset/confirm/:uuid/:token"
          element={<ConfirmRecover />}
        />
        <Route element={<UserDashboardLayout />}>
          <Route index path="/" element={<UserDashboard />} />
          <Route path="/users" element={<Users />} />
          <Route path="/users/admin" element={<AdminList />} />
          <Route path="/caroussel" element={<Caroussel />} />
          <Route path="/logging" element={<Logging />} />
          <Route path="/profile" element={<MyProfile />} />
          <Route path="/news" element={<ActualityNews />} />
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;

//glpat-rJzcbzWSBmRUCfPUysfh