import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { API } from "../../../services/axios";
import { URLS } from "../../../services/urls";

const AdminManage = ({ toEdit, onSuccess }) => {
  const { register, reset, handleSubmit } = useForm();
  const { t } = useTranslation();
  const { auth } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);

  const onSubmit = (data) => {
    console.log('Auth state:', auth);
    
    if (!data.last_name) {
      toast.error("Last name is required.");
      return;
    }

    if (!auth?.user?.id) {
      toast.error("User ID is not defined.");
      return;
    }

    let formData = new FormData();
    formData.append("first_name", data.first_name);
    formData.append("last_name", data.last_name);
    formData.append("email", data.email);
    formData.append("phone_number", data.phone_number);
    formData.append("user_level", data.user_level);
    formData.append("created_by", auth.user.id);

    setLoading(true);

    const apiCall = toEdit
      ? API.put(URLS.USERS.ADMIN.update(toEdit.id), formData)
      : API.post(URLS.USERS.ADMIN.create, formData);

    apiCall
      .then((resp) => {
        toast.success(
          toEdit
            ? t("textAdminEditionSuccess")
            : t("textUserCreatedSuccess")
        );
        setLoading(false);
        onSuccess();
        reset();
        window.$("#manageIndustryForm").modal("hide");
      })
      .catch((error) => {
        console.log(error);
        toast.error(
          error?.response?.data?.detail ??
            error?.response?.data?.message ??
            t("textAdminEditionError")
        );
        setLoading(false);
      });
  };

  return (
    <div
      className="modal fade"
      id="manageIndustryForm"
      tabIndex={-1}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content apply-job-form">
          <button
            className="btn-close"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
          <div className="modal-body pl-30 pr-30 pt-50">
            <div className="text-center">
              <p className="font-sm text-brand-2">
                {toEdit ? t("textEditAdmin") : t("textAddNew")}
              </p>
            </div>
            <form
              className="login-register text-start mt-20 pb-30"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="form-group">
                <label className="form-label" htmlFor="input-1">
                  {t("textFirstName")} *
                </label>
                <input
                  className="form-control"
                  id="input-1"
                  defaultValue={toEdit?.first_name}
                  type="text"
                  {...register("first_name", { required: true })}
                  placeholder="Steven"
                />
              </div>
              <div className="form-group">
                <label className="form-label" htmlFor="input-2">
                  {t("textLastName")} *
                </label>
                <input
                  className="form-control"
                  defaultValue={toEdit?.last_name}
                  type="text"
                  {...register("last_name", { required: true })}
                  placeholder={t("Job")}
                />
              </div>
              <div className="form-group">
                <label className="form-label" htmlFor="input-3">
                  {t("textEmail")} *
                </label>
                <input
                  className="form-control"
                  id="input-3"
                  defaultValue={toEdit?.email}
                  type="email"
                  {...register("email", { required: true })}
                  placeholder="Job@gmail.com"
                />
              </div>
              <div className="form-group">
                <label className="form-label" htmlFor="des">
                  {t("textPhoneNumber")}
                </label>
                <input
                  className="form-control"
                  defaultValue={toEdit?.phone_number}
                  type="text"
                  {...register("phone_number")}
                  placeholder={t("6 55 88 77 99")}
                />
              </div>
              <div className="form-group">
                <label className="form-label" htmlFor="des">
                  {t("textLevel")}
                </label>
                <select
                  className="form-control"
                  defaultValue={toEdit?.user_level}
                  {...register("user_level")}
                  placeholder={t("textLevel")}
                >
                  <option value="">{t("choose level")}</option>
                  <option value="it">{t("It")}</option>
                  <option value="writer">{t("Writer")}</option>
                  <option value="admin">{t("Admin")}</option>
                  <option value="manager">{t("Manager")}</option>
                </select>
              </div>

              <div className="form-group">
                <button
                  className="btn btn-default hover-up w-100"
                  type="submit"
                  name="login"
                >
                  {t("textAddNew")}
                  {loading && (
                    <div
                      className="spinner-border ml-10 spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminManage;
