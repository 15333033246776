export const en = {
    passMustMatch: "Passwords must match",
    required: "Required",
    noPassProvided: "No password provided.",
    passToShort: "Password is too short - should be 8 chars minimum.",
    passOnlyLatinLetters: "Password can only contain Latin letters.",
    startForFree: "Start for free Today",
    acessAllFeatures: "Access to all features. No credit card required.",
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    password: "Password",
    confirmPassword: "Confirm Password",
    agreeTerms: "Agree our terms and policy",
    learnMore: "Learn more",
    submitNRegister: "Submit & Register",
    alreadyHaveAccount: "Already have an account?",
    signIn: "Sign in",
    rememberMe: "Remenber me",
    forgotPassword: "Forgot Password",
    actionLogin: "Login",
    dontHaveAccount: "Don't have an Account?",
    signUp: "Sign up",
    phoneNumber: "Phone number",
    emailValidationError: "Email validation error;",
    myProfiles: "My profiles",
    menu_user: "User",
    addNew: "Add New",
    updateYourCV: "Update your CV",
    uploadFile: "Upload File",
    buttonDelete: "Delete",
    postTitle: "Post title",
    minSalary: "Minimum salary",
    maxSalary: "Maximum salary",
    userBio: "Bio",
    btnContinue: "Continue",
    profileSkills: "Profile skills",
    btnUpdate: "Update",
    titleEducation: "Education",
    educationFrom: "From",
    educationTo: "To",
    educationDegree: "Degree",
    educationSchool: "School",
    educationGpa: "Grade",
    educationfieldOfStudy: "Field of study",
    educationCollege: "College",
    text_college: "College",
    currentlyWorking: "Currently working here",
    currentlyStudying: "Currently Sudying here",
    currentlyShort: "Current",
    addTimeline: "Add Timeline",
    educationAdded: "Education successfully added on your profile",
    menuHome: "Home",
    myResume: "My Resume",
    editTimeline: "Edit Timeline",
    educationEdited: "Education successfully edited",
    deletionAreYouSure: 'Are you sure?',
    deletionIrreversibleAlert: "You won't be able to revert this!",
    deletionActionButton: "Yes, delete it!",
    deletionDeleted: "Deleted!",
    deletionSuccessMessage: 'Your deletion operation succeed.',
    deletionActionCancel: "Cancel",
    deletionProcess: "Deletion in progress",
    requestGlobalErrorMessage: "Something went wrong",
    experienceCompany: "Company",
    textDescrption: "Description",
    textResponsabilities: "Responsabilities",
    workExperience: "Work & Experience",
    experienceAdded: "Experience successfully added on your profile",
    experienceEdited: "Experience successfully edited",
    experienceSector: "Activity sector",
    markCvDefault: "Mark cv as default",
    deleteButtonText: "Delete",
    introEditedSuccessfuly: "Profile successfuly edited",
    postAJob: "Post a Job",
    textAdmin: "Admin",
    postNewJob: "Post New Job",
    jobInformations: "Job informations",
    jobTitle: "Job title",
    jobType: "Type",
    workplaceType: "Workplace type",
    workingTypePart: "Part time",
    workingTypeFull: "Full",
    workingTypeRemote: "Remote",
    workingTypeFreelancer: "Freelancer",
    jobExperienceYear: "Nombre d'années d'expérience",
    jobExperienceLevel: "Experience Level",
    jobExpEntry: "Entry",
    jobExpInterm: "Intermediate",
    jobExpSenior: "Senior",
    jobExpExpert: "Expert",
    jobMinSalary: "Min. salary",
    jobMaxSalary: "Max. salary",
    jobWorkPlaceOnsite: "On site",
    jobWorkPlaceRemote: "Remote",
    jobWorkPlaceHybrid: "Hybrid",
    jobApplicationEnd: "Application end",
    jobVisaSponsored: "Visa Sponsored",
    jobDescription: "Description",
    jobResponsabilities: "Job responsabilities",
    jobSpecialNote: "Special job note for user",
    jobIndustry: "Industry",
    jobCompany: "Company recruiting",
    jobSkills: "Skills",
    textJobs: "Jobs",
    jobCountry: "Country",
    jobCity: "City",
    jobProcess: "Recruitment processes (Separate with commas)",
    jobIncludePertners: "Include partners",
    textJobDetails: "Job Details",
    textJobApplications: "Job Applications",
    textCvTheque: "Cv Theque",
    textProfileDetails: "Profile details",
    textDownloadCv: "Download CV",
    textProfileSteps: "Etape du profil",
    textShortBio: "Short Bio",
    textSkills: "Skills",
    textAboutMe: "About Me",
    textOverview: "Overview",
    textExpectedSalary: "Expected Salary",
    textLinkedIn: "LinkedIn",
    textPhone: "Phone",
    textEmail: "Email",
    buttonSendMessage: "Send Message",
    textMyProfile: "My Profile",
    textUpdateProfile: "Update your profile",
    textUploadAvatar: "Upload Avatar",
    textFirstName: "First Name",
    textLastName: "Last Name",
    textPhoneNumber: "Phone Number",
    textLanguage: "Language",
    buttonSaveChange: "Save Change",
    textContactInformation: "Contact Information",
    textLngFrench: "French",
    textLngEnglish: "English",
    textDashboard: "Dashboard",
    textLogout: "Logout",
    textMyProfile: "My Profile",
    textcvTheque: "CV Theque",
    textJobs: "Jobs",
    jobWorkPlace: "Work place",
    jobApplicationStarts: "Application Start",
    textUploadImage: "Upload Image",
    textJobCreated: "Job created !",
    textJobEdited: "Job edited !",
    textMonth: "Month",
    textJobDetails: "Job details",
    textEditThisJob: "Edit this job",
    textDelete: "Delete",
    textViewApplications: "View applications",
    textEmploymentInfo: "Employment Information",
    textIndustry: "Industry",
    textJobLevel: "Job level",
    textSalary: "Salary",
    textExperience: "Experience",
    textYears: "Years",
    textJobType: "Job type",
    textCreated: "Created",
    textDeadline: "Deadline",
    textLocation: "Location",
    textApplyNow: "Apply now",
    textShareJob: "Share this",
    textEmail: "Email",
    textJobDetails: "Job Details",
    textSkills: "Skills",
    textDescrption: "Description",
    textCandidatures: "Candidatures",
    textApplicationsReceived: "Applications received for this post",
    textSearch: "Search",
    textApplications: "Applications",
    textDescription: "Description",
    textSteps: "Steps",
    tetSkills: "Skills",
    textJObDetails: "Job details",
    texIsActive: "Is active",
    textNewJob: "Post New Job",
    textEditJob: "Edit Job",
    textStatus: "Status",
    text_sent: "Sent",
    text_in_progress: "In progress",
    text_interview: "Interview",
    text_test: "Test",
    text_hr: "RH",
    text_human_ressources: "Hman ressources",
    text_rejected: "Rejected",
    text_validated: "Validated",
    text_waiting_user: "Waiting your confirmation",
    text_accepted: "Accepted",
    text_canceled: "Canceled",
    textNoItemFound: "No item found",
    txtConfig: "Configurations",
    textIndustries: "Industries",
    textCompanies: "Companies",
    textCollege: "College",
    text_industry: "Industry",
    textLogo: "Logo",
    textIcon: "Icon",
    textName: "Name",
    textDescription: "Description",
    textAddNew: "+ Add New",
    textUploadLogoImage: "Upload logo",
    textUploadIconImage: "Upload icon",
    textConfigCreatedSuccess: "Configuration successfully created !",
    textConfigCreatedFailed: "An error occured while adding the configuration ",
    textConfigEditionFailed: "An error occured while editing the configuration ",
    textConfigEditionSuccess: "Configuration successfully edited !",
    textCompanyUrl: "Company url",
    textPartner: "Partner",
    textCountry: "Country",
    textAdvance: "Advance",
    textCancel: "Cancel",
    textAdvanced: "Profile Advanced",
    textAdvancingError: "Profile Advancing error",
    textApplicationCanceled: "The application has been successfuly canceled",
    textApplicationCancelingError: "A error occured while canceling the application.",
    textConfigCreatedError: "Configuration error",
    textFilters: "Filters",
    textMinAge: "Min age",
    textMaxAge: "Max age",
    textFilterWorking: "Actualy working",
    textNotWorking: "Actualy not working",
    textTrainingLevelType: "Level and type of training",
    textxpCategory: "Experience category",
    textSubscriptionDate: "Subscription date",
    textAdminArea: "Admin area",
    textPostJob: "Post Job",
    textProfileEditedSuccess: "Profile sucessfuly edited",
    textApplicationStatus: "Application status",
    textStatusChoose: "Choose a status",
    textTrainingLevelTypeChoose: "Choose a training field",
    textChoose: "Choose",
    textEntry: "Entry",
    textIntermediate: "Intermediate",
    textSenior: "Senior",
    textExpert: "Expert",
    textResetForm: "Reset Filters",
    textLatestJobs: "Latest Jobs",
    textInterviewSchedules: "Interview Schedules",
    textArticlesAdded: "Articles Added",
    textCVAdded: "CV Added",
    textThisMonth: "This Month",
    textApplication: "Applications",
    textUsers: "Users",
    textOurProcess: "Our Process",
    textNoJobFound: "No job found",
    textAll: "All",
    textLogin: "Login",
    textRegister: "Register",
    textSignIn: "Login",
    textNoJob: "No job found",
    textLoginError: "Unable to log in with provided credentials.",
    textWelcomeBack: "Welcome back!",
    textAtMost: "The email should have at most 50 characters",
    textValidEmail: "Email address must be a valid address",
    textEmailNotFound: "Email not found",
    textResetPw: "Reset password",
    textRecoverPwEmailSend: "Password reset e-mail has been sent.",
    textConfirmError: "Password reset confirmation failed",
    textPwMustMatch: "Your passwords do no match",
    textSetNewPassword: "Set New Password",
    textNoNotifs: "No notifications found",
    textNotifications: "Notifications",
    textTitle: "Title",
    textContent: "Content",
    textNotificationsDetails: "Notification Details",
    textNotifDetailsError: "Sorry, we cannot display this notification, please try later",
    textProfileEditedError: "Sorry, we can't edit the profile, and error occured",
    textUploadLogo: "Upload logo",
    textEditIndustry: "Industry edition",
    textInvalidUrl: "Invalid URL",
    textDiploms: "Diploms",
    textMessages: "Messages",
    text_skills: "Skills",
    textEdit: "Edit",
    text_diploms: "Diploms",
    text_messages: "Messages",
    textCompany: "Company",
    textAvailablePosts: "Available place",

    textProfiles: "Profiles",
    textRecommendedJobs: "Recommended jobs",
    textTopRecruitment: "Top recruitment",
    textApplicationDetails: "Application details",
    textHistory: "History",
    textApplicationInformations: "Application informations",
    textAppliedOn: "Applied on",
    textIsActive: "Is active",
    textYes: "Yes",
    textNo: "No",
    textStep: "Step",
    textJob: "Job",
    textPreviousStep: "Previous step",
    textApplicationNotFound: "Applicationn not found",
    textActions: "Actions",
    textCancelApplication: "Cancel my application",
    textAcceptJob: "Accept job",
    textYesCancel: "Yes, cancel",
    textApplicationCancelError: "An error occured while cancelling your application",
    textApplicationCancelled: "Job application canncelled !",
    textApplicationAccepted: "Application accepted",
    textApplicationAcceptationError: "Application acceptation failed",
    textIntroduction: "Introduction",
    textCertifications: "Certifications",
    textLanguages: "Languages",
    textReferences: "References",
    textAddEducation: "Add education",
    textEditEducation: "Edit education",
    textPrevious: "Previous",
    textNext: "Next",
    textAddExperience: "Add experience",
    textEditExperience: "Edit experoence",
    textCertifName: "Certification name",
    textDate: "Date",
    textCertifAdded: "Certification successfully added",
    textCertifAddingError: "Certification creation error!",
    textAddCertification: "Add certification",
    textEditCertification: "Edit certification",
    textJoinedFile: "Joined files",
    textLangName: "Language name",
    textLevel: "Level",
    textAddLanguage: "Add language",
    textEditLanguage: "Edit language",
    textLanguageAddingError: "Language adding error !",
    languageEdited: "Language successfully edited",
    textLanguageAdded: "Language successfully added",
    textName: "Name",
    textAddReference: "Add reference",
    textEditReference: "Ediit reference",
    textReferenceAddingError: "Reference adding error !",
    referenceEdited: "Reference successfully edited",
    textReferenceAdded: "Reference successfully added",
    textViewResumes: "View My resumes",
    textCloseOffer: "Close the offer",
    textCloseApplications: "Close applications",
    textJobAdvanced: "Job successfully advanced!",
    textJobAdvancedError: "Job advancing error!",
    textJobClosed: "Job applications successfully closed!",
    textJobClosedError: "Job applications closing error!",
    textNberPlaces: "Opened places",
    textNberAppl: "Applications",
    textGender: "Gender",
    textAge: "Age",
    textChoose: "Choose an option",
    textMale: "Male",
    textFemale: "Female",
    textMale: "Male",
    textXtraDataMessage: "Please, fill this section only if this job require extra informations from the user.",
    textAddEntry: "Add an entry",
    textType: "Type",
    textRequired: "Required",
    textLabel: "Label",
    textYesNo: "Yes or No",
    textTrueFalse: "True or False",
    textNumber: "Number",
    textCharacters: "Text",
    textEditAJob: "Edit job",
    textSeperateSkills: "To add many skills, please separate with a coma (,)",
    textFullname: "Full name",
    textExport: "Export",
    textSelectedItems: "Seleted item",
    textCancelingApplication: "Canceling application... ",
    textCustomFilters: "Custom filters",
    text_messagess: "Messages",
    textMessage: "Message",
    textChoosePeriod: "Select a period",
    textSubject: "Subject",
    text_submition: "Submition",
    text_rejection: "Rejection",
    text_validation: "Validation",
    textSalary: "Salary",
    textExportResult: "Export Result",
    textExportSelected: "Export Selected",
    textDetails: "Details",
    textSeperateDiploms: "To add many diploms, please separate with a coma (,)",
    textJobEditedFailed: "Job edition failed",
    textJobCreationFailed: "Job creation failed",
    textPreparingDonwload: "Preparation of export",
    textDownloadSuccess: "Export success",
    textDownloadFailed: "Export failed",
};
