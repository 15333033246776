import axios from "axios";
import { URLS } from "./urls";
import { refreshToken } from "./refresh_token";
import { getUserDetails } from "../core/utils/functions";

const API = axios.create({
  baseURL: URLS.BASE_URL,
  timeout: 100000,
});

const AUTHAPI = axios.create({
  baseURL: URLS.BASE_URL,
});

API.interceptors.request.use(
  async (config) => {
      const user = getUserDetails()
      console.log(user?.access_token)
      // const session = token;
      const token = user?.access_token;

      if (token) {
          config.headers = {
              ...config.headers,
              Authorization: user ? `Bearer ${user?.access_token ?? ""}` : undefined
          };
      }
      return config;
  },
  (error) => Promise.reject(error)
);
// Response interceptor for API calls
API.interceptors.response.use(
  (response) => {
      return response;
  },
  async function (error) {
      const originalRequest = error.config;
      if (error.response?.status === 403 || (error.response?.status === 401 && !originalRequest._retry)) {
          originalRequest._retry = true;
          const access_token = await refreshToken();
          // CONTACTAPI.defaults.headers.common['Authorization'] = access_token;
          originalRequest.headers = {
              ...originalRequest.headers,
              Authorization: `Bearer ${access_token}`
          };
          return axios(originalRequest);
      }
      return Promise.reject(error);
  }
);

export { API, AUTHAPI };
