import "./App.css";
import AppRoutes from "./route/AllRoutes";
import { Provider } from "react-redux";
import { store } from "./store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Auth from "./component/auth/index";
import ConfigSWR from "./services/swr";
import "./translations/translations";

function App() {
  return (
    <Provider store={store}>
      <ConfigSWR>
        <Auth>
          <AppRoutes />
        </Auth>
        <ToastContainer />
      </ConfigSWR>
    </Provider>
  );
}

export default App;
