import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  login_request: false,
  login_sucess: false,
  login_failed: false,
  auth: undefined,
};
// Crée un "slice" pour les actions liées à l'authentification
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // Reducteur pour gérer la requête de connexion
    loginRequest: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.login_request = true;
      state.login_sucess = false;
      state.login_failed = false;
    },
    // Reducteur pour gérer la réussite de la connexion
    loginSuccess: (state, action) => {
      state.login_request = false;
      state.login_sucess = true;
      state.login_failed = false;
      state.auth = action.payload; // Stocke les données d'authentification
    },
    // Reducteur pour gérer l'échec de la connexion
    loginFailed: (state) => {
      state.login_request = false;
      state.login_sucess = false;
      state.login_failed = true;
    },
    // Reducteur pour mettre à jour les données utilisateur
    updateUserData: (state, action) => {
      if (state.auth) {
        state.auth.user = action.payload;
      }
    },
  },
});

// Les créateurs d'actions sont générés pour chaque fonction de réducteur de cas
export const { loginRequest, loginSuccess, loginFailed, updateUserData } =
  authSlice.actions;

export default authSlice.reducer;
