import { toast } from "react-toastify";
import { API } from "./axios";
import { URLS } from "./urls";

export const logout = (t) => {
  const id = toast.loading(t("Logining Out"));

  API.get(URLS.AUTHS.LOGOUT)
    .then(() => {
      window.localStorage.removeItem("userDetails");
      window.location.pathname = "/login";
      toast.dismiss(id);
    })
    .catch((error) => {
      toast.update(id, {
        render: error?.response?.data?.detail,
        type: "error",
        isLoading: false,
        autoClose: true,
      });
    });
};
