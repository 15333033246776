import { Link } from "react-router-dom";
import useSWR from "swr";
import { URLS } from "../../services/urls";
import AuthFooter from "../../component/footer/index";
import { useTranslation } from "react-i18next";
import CountUp from "react-countup";
import { useEffect, useState } from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { API } from "../../services/axios";
import moment from "moment";

const UserDashboard = () => {
  // const { data, isLoading } = useSWR(URLS.DASHBOARD.admin);
  const { data: jobs, isLoading: loadingJobs } = useSWR(
    URLS.JOBS.list("", 1, "")
  );
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
      isInitial: true,
    },
  ]);

  useEffect(() => {
    if (date[0].isInitial && date[0].startDate != date[0].endDate) {
      API.get(URLS.DASHBOARD.admin)
        .then((resp) => {
          setData(resp.data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      setLoading(true);
      API.get(
        URLS.DASHBOARD.adminInterval(
          moment(date[0].startDate).format("YYYY-MM-DD"),
          moment(date[0].endDate).format("YYYY-MM-DD")
        ),
        {
          params: {
            start: moment(date[0].startDate).format("YYYY-MM-DD"),
            end: moment(date[0].endDate).format("YYYY-MM-DD"),
          },
        }
      )
        .then((resp) => {
          setLoading(false);
          setData(resp.data);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, [date]);

  return (
    <div className="box-content">
      <div className="box-heading">
        <div className="box-title">
          <h3 className="mb-35 text-left">{t("textDashboard")}</h3>
        </div>
        <div className="box-breadcrumb">
          <div className="breadcrumbs">
            <ul>
              <li>
                {" "}
                <Link className="icon-home" to="/">
                  {t("textDashboard")}
                </Link>
              </li>
              <li>
                <span>{t("textDashboard")}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-8 col-12">
          <div className="section-box">
            <div className="row">
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6">
                <div className="card-style-1 hover-up">
                  <div className="card-image">
                    {" "}
                    <img
                      src="assets/imgs/page/dashboard/man.svg"
                      alt="Cible rh emploie"
                    />
                  </div>
                  <div className="card-info background-image-none p-0">
                    <div className="card-title">
                      <h3>
                        {data?.job.active}
                        <span
                          className={`font-sm status ${
                            data?.job?.growth > 0
                              ? "up"
                              : data?.job?.growth === 0
                              ? ""
                              : "down"
                          }`}
                        >
                          {!loading &&
                            parseFloat(String(data?.job?.growth)).toFixed()}
                          <span> %</span>
                        </span>
                      </h3>
                    </div>
                    <span className="font-sm">
                      {t("textThisMonth")}:{" "}
                      {loading ? (
                        <span
                          cclassName="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <CountUp
                          start={0}
                          delay={0}
                          end={data?.job?.this_month}
                        />
                      )}
                    </span>
                    <p className="color-text-paragraph-2 text-left">
                      {t("textUsers")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6">
                <div className="card-style-1 hover-up">
                  <div className="card-image">
                    {" "}
                    <img
                      src="assets/imgs/page/dashboard/look.svg"
                      alt="Cible rh emploie"
                    />
                  </div>
                  <div className="card-info background-image-none p-0">
                    <div className="card-title">
                      <h3>
                        {data?.job.active}
                        <span
                          className={`font-sm status ${
                            data?.job?.growth > 0
                              ? "up"
                              : data?.job?.growth === 0
                              ? ""
                              : "down"
                          }`}
                        >
                          {!loading &&
                            parseFloat(String(data?.job?.growth)).toFixed()}
                          <span> %</span>
                        </span>
                      </h3>
                    </div>
                    <span className="font-sm">
                      {t("textThisMonth")}:{" "}
                      {loading ? (
                        <span
                          cclassName="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <CountUp
                          start={0}
                          delay={0}
                          end={data?.job?.this_month}
                        />
                      )}
                    </span>
                    <p className="color-text-paragraph-2 text-left">
                      {t("textJobs")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6">
                <div className="card-style-1 hover-up">
                  <div className="card-image">
                    {" "}
                    <img
                      src="assets/imgs/page/dashboard/doc.svg"
                      alt="Cible rh emploie"
                    />
                  </div>
                  <div className="card-info background-image-none p-0">
                    <div className="card-title">
                      <h3>
                        {data?.articles.active}
                        <span
                          className={`font-sm status ${
                            data?.articles?.growth > 0
                              ? "up"
                              : data?.articles?.growth === 0
                              ? ""
                              : "down"
                          }`}
                        >
                          {!loading &&
                            parseFloat(String(data?.articles?.growth)).toFixed(
                              2
                            )}
                          <span> %</span>
                        </span>
                      </h3>
                    </div>
                    <span className="font-sm">
                      {t("textThisMonth")}:{" "}
                      {loading ? (
                        <span
                          cclassName="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <CountUp
                          start={0}
                          delay={0}
                          end={data?.articles?.this_month}
                        />
                      )}
                    </span>
                    <p className="color-text-paragraph-2 text-left">
                      {t("textArticlesAdded")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6">
                <div className="card-style-1 hover-up">
                  <div className="card-image">
                    {" "}
                    <img
                      src="assets/imgs/page/dashboard/man.svg"
                      alt="Cible rh emploie"
                    />
                  </div>
                  <div className="card-info background-image-none p-0">
                    <div className="card-title">
                      <h3>
                        {data?.profiles.active}
                        <span
                          className={`font-sm status ${
                            data?.profiles?.growth > 0
                              ? "up"
                              : data?.articles?.growth === 0
                              ? ""
                              : "down"
                          }`}
                        >
                          {data?.articles?.growth}
                          <span> %</span>
                        </span>
                      </h3>
                    </div>
                    <span className="font-sm">
                      {t("textThisMonth")}:{" "}
                      {loading ? (
                        <span
                          cclassName="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <CountUp
                          start={0}
                          delay={0}
                          end={data?.application?.this_month}
                        />
                      )}
                    </span>
                    <p className="color-text-paragraph-2 text-left">
                      {t("textCVAdded")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-12">
          <div>
            <h4>{t("textChoosePeriod")}</h4>
            <DateRange
              editableDateInputs={true}
              onChange={(item) => {
                setDate([{ ...item.selection, isInitial: false }]);
                console.log(item.selection);
              }}
              moveRangeOnFirstSelection={false}
              ranges={date}
            />
          </div>
        </div>
      </div>
      <AuthFooter />
    </div>
  );
};

export default UserDashboard;
