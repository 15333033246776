import { useState } from "react";
import { useTranslation } from "react-i18next";
import { URLS } from "../../services/urls";
import useSWR from "swr";
import ManageCaroussel from "./ManageCaroussel";
import { LuEye, LuTrash } from "react-icons/lu";
import { confirmDeletion } from "../../component/dletion";
import { Link } from "react-router-dom";
import { DEFAULT_PAGE_COUNT } from "../../core/utils/consts";
import dash from "../../assets/img/dashboard/dash.png";
import { Button, Row } from "reactstrap";
import { formatDate } from "../../core/utils/date.utils";
import ManageImage from "./ManageImage";
const Caroussel = () => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [inputSearch, setInputSearch] = useState("");
  const [toEdit, setToEdit] = useState();

  const { data, isLoading, mutate } = useSWR(URLS.CAROUSSEL.list(search));
  // console.log(data);

  const lastData = data?.results?.sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  )[0];

  const { t } = useTranslation();

  const handleSearch = () => {
    setSearch(inputSearch);
  };
  const handleChangePage = (event, pg) => {
    event.preventDefault();
    setPage(pg);
  };

  const handleEdit = (data) => {
    setToEdit(data);
    window.$("#manageIndustryForm").modal("show");
  };
  const handleShowManageImage = () => {
    window.$("#manageImageForm").modal("show");
  };

  const onSuccess = () => {
    mutate();
  };

  return (
    <div>
      <ManageCaroussel toEdit={toEdit} onSuccess={onSuccess} />
      <ManageImage />
      <div className="box-content">
        <div className="box-title">
          <h3 className="mb-35 fw-bold">{t("Carrousel")}</h3>
        </div>
        <div className="page-content">
          <div className="container-fluid">
            {/* visually carrousel */}
            <Row>
              <div
                id="carouselExampleCaptions"
                className="panel-white mb-30"
                data-bs-ride="carousel"
              >
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img
                      src={lastData?.image}
                      class="d-block w-100"
                      alt="..."
                    />
                    <div class="carousel-caption d-none d-md-block">
                      <Row>
                        {" "}
                        <Button>Title:{lastData?.title}</Button>
                      </Row>
                      <Row>
                        {" "}
                        <Button>Subtitle:{lastData?.subtitle}</Button>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            </Row>
            {/* visually carrousel */}
            <Row>
              <div class=" d-none d-md-block">
                <Row>
                  {" "}
                  <p>Carrousel Detal Job</p>
                </Row>
                <Row>
                  {" "}
                  <p>Dimension recommended:Max accepted: 6164x2553</p>
                </Row>
              </div>
              <div class=" d-none d-md-block">
                <Row>
                  {" "}
                  <p>Background Job by Location</p>
                </Row>
                <Row>
                  {" "}
                  <p>Dimension recommended:Max accepted: 288x259</p>
                </Row>
              </div>
            </Row>
            <Row></Row>
            <div className="col-md-6 col-12">
              <div>
                <div className="d-flex w-100">
                  <button
                    onClick={handleShowManageImage}
                    type="submit"
                    className="btn btn-default ml-auto"
                  >
                    {t("Define Location")}
                  </button>
                </div>
              </div>
            </div>
            <br />
            <Row>
              <div className="panel-white mb-30">
                <div className="p-3">
                  <div className="row">
                    <div className="col-md-6 col-12">
                      <div>
                        <div className="d-flex">
                          <div className="form-group mx-sm-3 mb-0">
                            <input
                              value={inputSearch}
                              onChange={(e) => setInputSearch(e.target.value)}
                              type="text"
                              className="form-control"
                              id="inputPassword2"
                              placeholder={t("textSearch")}
                            />
                          </div>
                          <button
                            onClick={handleSearch}
                            type="submit"
                            className="btn btn-default "
                          >
                            {t("textSearch")}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div>
                        <div className="d-flex w-100">
                          <button
                            data-bs-toggle="modal"
                            data-bs-target="#manageIndustryForm"
                            type="submit"
                            className="btn btn-default ml-auto"
                          >
                            {t("textAddNew")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="panel-white mb-30">
                <div className="box-padding">
                  {isLoading && (
                    <div className="d-flex">
                      <div className="col-12 mb-10 d-flex">
                        <span
                          className="spinner-border m-auto"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </div>
                    </div>
                  )}
                  {!isLoading && data && (
                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">{t("Image")}</th>
                            <th scope="col">{t("Title")}</th>
                            <th scope="col">{t("Subtitle")}</th>
                            <th scope="col">{t("Step")}</th>
                            <th scope="col">{t("Description")}</th>
                            <th scope="col">{t("CreatedAt")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.results?.map((config) => (
                            <tr className="hover">
                              <th scope="row" key={config.id}>
                                <span
                                  style={{
                                    height: "50px",
                                    width: "50px",
                                    overflow: "hidden",
                                    display: "block",
                                  }}
                                >
                                  <img
                                    src={
                                      config.image ??
                                      "https://picsum.photos/200"
                                    }
                                    width={"100%"}
                                    alt="carrousel"
                                  />
                                </span>
                              </th>
                              <td style={{ verticalAlign: "middle" }}>
                                {config?.title}
                              </td>
                              <td style={{ verticalAlign: "middle" }}>
                                {config?.subtitle}
                              </td>
                              <td style={{ verticalAlign: "middle" }}>
                                {config?.image_list?.step}
                              </td>
                              <td style={{ verticalAlign: "middle" }}>
                                {config?.image_list?.description}
                              </td>
                              <td style={{ verticalAlign: "middle" }}>
                                {formatDate(config?.created_at)}
                              </td>
                              <td style={{ verticalAlign: "middle" }}>
                                <button
                                  onClick={() => handleEdit(config)}
                                  className="btn btn-default p-1 mr-3"
                                  style={{ height: "30px", marginRight: "5px" }}
                                >
                                  <img
                                    src="/assets/imgs/page/dashboard/edit.svg"
                                    alt=""
                                  />
                                </button>

                                <button
                                  onClick={() =>
                                    confirmDeletion(
                                      URLS.CAROUSSEL.delete(config.id),
                                      t,
                                      onSuccess
                                    )
                                  }
                                  className="btn btn-default p-1"
                                  style={{ height: "30px" }}
                                >
                                  <span className="d-flex" style={{}}>
                                    <LuTrash />
                                  </span>
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
              <div className="panel-white mb-30">
                <div className="p-3">
                  <div className="paginations">
                    <ul className="pager">
                      <li>
                        <Link
                          tabIndex={-1}
                          onClick={(event) => handleChangePage(event, page - 1)}
                          className={`pager-prev ${
                            page === 1 ? "disabled" : ""
                          }`}
                          to="#"
                        ></Link>
                      </li>
                      {data &&
                        new Array(Math.ceil(data.count / DEFAULT_PAGE_COUNT))
                          .fill(3)
                          .map((pag, index) => (
                            <li
                              key={"page" + index}
                              className={` ${
                                page === index + 1 ? "active" : ""
                              }`}
                              aria-current="page"
                            >
                              <Link
                                className={`pager-number ${
                                  page * DEFAULT_PAGE_COUNT ===
                                  Math.ceil(data.count)
                                    ? "disabled"
                                    : ""
                                } ${page === index + 1 ? "active" : ""}`}
                                to="#"
                                tabIndex={-1}
                                onClick={(event) =>
                                  handleChangePage(event, index + 1)
                                }
                              >
                                {index + 1}
                              </Link>
                            </li>
                          ))}
                      <li>
                        <Link
                          tabIndex={-1}
                          className={`pager-next ${
                            page === 1 ? "disabled" : ""
                          }`}
                          to="#"
                        ></Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Caroussel;
