import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { API } from "../../services/axios";
import { URLS } from "../../services/urls";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import { styles } from "../../core/utils/reactSelect.js";
import CreatableSelect from "react-select/creatable";

const ManageImage = ({ toEdit, onSuccess }) => {
  const { register, reset, handleSubmit } = useForm();
  const { t } = useTranslation();
  const { auth } = useSelector((state) => state.auth);
  const { id } = useParams;

  const [loading, setLoading] = useState(false);

  const [searchCarrousel, setSearchCarrousel] = useState("");
  const [carrousel, setCarrousel] = useState();

  const {
    data: carrousels,
    isLoading: loadingContainers,
    mutate,
  } = useSWR(URLS.CAROUSSEL.list(searchCarrousel));

  const onSubmit = (data) => {
    let formData = new FormData();
    formData.append("step", data.step);
    formData.append("description", data.description);
    formData.append("caroussel", carrousel.value);

    setLoading(true);
    if (toEdit) {
      API.put(URLS.CAROUSSEL.CAROUSSEL_OTHER.update(toEdit.id), formData)
        .then((resp) => {
          toast.success(t("textConfigEditionSuccess"));
          setLoading(false);
          onSuccess();
          reset();
          window.$("#manageImageForm").modal("hide");
        })
        .catch((error) => {
          toast.error(
            error?.response?.data?.detail ??
              error?.response?.data?.message ??
              t("textConfigEditionError")
          );
          setLoading(false);
        });
    } else {
      API.post(URLS.CAROUSSEL.CAROUSSEL_OTHER.create, formData)
        .then((resp) => {
          toast.success(t("textConfigCreatedSuccess"));
          setLoading(false);
          onSuccess();
          reset();
          window.$("#manageImageForm").modal("hide");
        })
        .catch((error) => {
          toast.error(
            error?.response?.data?.detail ??
              error?.response?.data?.message ??
              t("textConfigCreatedError")
          );
          setLoading(false);
        });
    }
  };

  return (
    <div
      className="modal fade"
      id="manageImageForm"
      tabIndex={-1}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content apply-job-form">
          <button
            className="btn-close"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
          <div className="modal-body pl-30 pr-30 pt-50">
            <div className="text-center">
              <p className="font-sm text-brand-2">
                {toEdit ? t("textEditCaroussel") : t("textAddNew")}
              </p>
              {/* <h2 className="mt-10 mb-5 text-brand-1 text-capitalize">Start your career today</h2> */}
              {/* <p className="font-sm text-muted mb-30">Please fill in your information and send it to the employer.                   </p> */}
            </div>
            <form
              className="login-register text-start mt-20 pb-30"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="form-group">
                <label className="form-label" htmlFor="des">
                  {t("textStep")}
                </label>
                <select
                  className="form-control"
                  defaultValue={toEdit?.step}
                  {...register("step")}
                  placeholder={t("textStep")}
                >
                  <option value="">{t("choose Location")}</option>
                  <option value="job">{t("job")}</option>
                  <option value="user">{t("user")}</option>
                  <option value="profile">{t("profile")}</option>
                  <option value="country">{t("country")}</option>
                  <option value="sector">{t("sector")}</option>
                </select>
              </div>
              <div className="form-group">
                <label className="form-label" htmlFor="input-2">
                  {t("Description")} *
                </label>
                <input
                  className="form-control"
                  id="input-2"
                  defaultValue={toEdit?.description}
                  type="text"
                  {...register("description", { required: true })}
                  placeholder="description"
                />
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="form-group mb-30">
                  <label className="font-sm mb-10">
                    {t("Caroussel")} <span className="text-primary">*</span>
                  </label>
                  <div>
                    <CreatableSelect
                      id="caroussel"
                      inputId="select-city"
                      styles={styles}
                      value={carrousel}
                      isSearchable
                      onInputChange={(value) => setSearchCarrousel(value)}
                      isLoading={loadingContainers}
                      onChange={(newValue) => setCarrousel(newValue)}
                      options={
                        carrousels?.results?.map((i) => {
                          return { label: i.title, value: i.id };
                          // return { label: i.display_name, value: String(i.url).split("/")[String(i.url).split("/").length-2] };
                        }) ?? []
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="form-group">
                <button
                  className="btn btn-default hover-up w-100"
                  type="submit"
                  name="login"
                >
                  {t("textAddNew")}
                  {loading && (
                    <div
                      className="spinner-border ml-10 spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
              {/* <div className="text-muted text-center">Do you need support? <a href="page-contact.html">Contact Us</a></div> */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageImage;
